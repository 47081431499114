import { mapState } from "vuex";
export default {
  data() {
    return {
      pages: 0,
      pageIndex: 1,
      upFooter: false,
    };
  },

  computed: {
    ...mapState(["baseInfo", 'isLogin', 'regAuth']),
  },
  methods: {
    popZindex() {
      const dom = this.$refs.popdom
      document.body.appendChild(dom)
    },
    isLoginTip(fun) {
      if (!this.isLogin) {
        this.$confirm("请先登录账号，才能了解更多", "温馨提示", {
          confirmButtonText: "确定",
          showCancelButton: false,
          customClass: "el-pop-wid",
        })
          .then(() => {
            this.$router.push("/user/login");
          })
          .catch(() => {
            console.log(11);
          });
      } else {
        fun()
      }
    },

    // base接口字段输出内容
    baseText(val) {
      return this.baseInfo.find(item => item.configKey === val).configValue
    },
    contactWeb() {
      window.open(this.baseInfo[6].configValue);
    },
    // KOIpay账号正则
    accountKOI(val) {
      const account = /^[k]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
      return !!account.test(val);
    },
    // EBpay账号正则
    accountEB(val) {
      const account = /^[e]{1}[b]{1}[A-Za-z0-9]{15,32}$/;
      return !!account.test(val);
    },
    // USDT账号正则 -TRC20
    accountUSDTTRC(val) {
      const account = /^[T]{1}[A-Za-z0-9]{15,33}$/;
      return !!account.test(val);
    },
    // USDT账号正则 - ERC20
    accountUSDTERC(val) {
      const account = /^[0]{1}[x]{1}[A-Za-z0-9]{15,40}$/;
      return !!account.test(val);
    },
    // 账号脱敏
    accountShow(val) {
      const obj = "**** **** **** **** " + val.slice(val.length - 4);
      return obj;
    },
    // 手机号码脱敏
    phoneShow(mobile) {
      let reg = mobile.slice(0, 3) + " **** " + mobile.slice(mobile.length - 4);
      return reg;
    },
    // 邮箱脱敏
    emailHide(email) {
      var avg;
      var splitted;
      var email1;
      var email2;
      splitted = email.split("@");
      email1 = splitted[0];
      avg = email1.length / 2;
      email1 = email1.substring(0, email1.length - avg);
      email2 = splitted[1];
      return email1 + "***@" + email2; // 输出为11223***@qq.com
    },
    // 是否登录跳转
    isJumpLogoUrl(val) {
      if (!this.isLogin) {
        return "/pages/login/login";
      } else {
        return val;
      }
    },
    // 复制
    copyLink(val) {
      let text = val;
      let textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.readOnly = "readOnly";
      document.body.appendChild(textarea);
      textarea.select(); // 选中文本内容
      textarea.setSelectionRange(0, text.length);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      document.execCommand("copy");
      textarea.remove();
    },

    // 金钱加逗号
    amountRule(amount) {
      let defaultAmount = " ";
      let setAmount = amount + " ";
      if (
        setAmount != "null" &&
        setAmount != "" &&
        setAmount != "undefined" &&
        setAmount != "--"
      ) {
        defaultAmount = Number(setAmount)
          .toString()
          .replace(/\d(?=(\d{3})+\b)/g, "$&,");
        return defaultAmount;
      } else {
        return defaultAmount;
      }
    },
  },
};
