<template>
  <div class="vipCenter-bg">
    <div class="yx-wid-12 vipCenter">
      <div class="user-left" v-if="userInfo">
        <div class="user-nav" v-for="(item, index) in menus" :key="index">
          <div class="user-nav-title">
            {{ item.name }}
          </div>
          <ul class="user-nav-ul">
            <li v-for="(row, index) in item.list" :key="index" :class="{ hover: row.aid === cwmenusInd }"
              @click="tabMenuCw(row)">
              <span class="icon">
                <img :src="row.icon" class="mr" />
                <img :src="row.iconHover" class="xz" />
              </span>
              <span class="text"> {{ row.name }}</span>
              <span class="number" v-if="row.aid === 9 && message > 0">{{ message }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="user-right">
        <div class="user-right-info" v-if="userInfo">
          <div class="photo" @click="setPhoto">
            <span>更改头像</span>
            <img v-lazy="userInfo.photoUrl" />
          </div>
          <div class="info" v-if="accountMoney && accountMoney.userBalance >= 0">
            <span class="name">{{ userInfo.userName }}</span>
            <p>账户余额：<i class="money">¥{{ accountMoney.userBalance ? accountMoney.userBalance.toFixed(2) : '0.00' }}</i>{{
}}
            </p>
          </div>
          <div class="info-list">
            <ul>
              <li>
                <img
                  :src="require('@/assets/images/mine/icon/user01' + (userInfo.realName && userInfo.sex >= 0 && userInfo.birthDate ? '-1' : '') + '.png')" />
                基本资料：<span class="text-blue" @click="jumpInfo('user')"
                  v-if="!(userInfo.realName && userInfo.sex >= 0 && userInfo.birthDate)">未完善</span>
                <span v-else>已完善</span>
              </li>
              <li>
                <img src="@/assets/images/mine/icon/user03.png" />
                账户安全等级：<span>{{ infoLevel }}</span>
              </li>
              <li>
                <img src="@/assets/images/mine/icon/user04.png" />
                密码修改：<span class="text-blue" @click="jumpInfo('pwd')">去修改</span>
              </li>
              <li>
                <img
                  :src="require('@/assets/images/mine/icon/user02' + (userBankCardList && userBankCardList.length ? '-1' : '') + '.png')" />
                绑定银行卡：<span class="text-blue" @click="jumpInfo('bank')"
                  v-if="!(userBankCardList && userBankCardList.length)">去绑定</span>
                <span v-else>已绑定</span>
              </li>
              <li>
                <img :src="require('@/assets/images/mine/icon/user05' + (userInfo.mobile ? '-1' : '') + '.png')" />
                绑定手机号码：<span class="text-blue" @click="jumpInfo('phone')" v-if="!userInfo.mobile">去绑定</span>
                <span v-else>已绑定</span>
              </li>
              <li>
                <img :src="require('@/assets/images/mine/icon/user06' + (userInfo.email ? '-1' : '') + '.png')" />
                绑定邮箱：<span class="text-blue" @click="jumpInfo('mail')" v-if="!userInfo.email">去绑定</span>
                <span v-else>已绑定</span>
              </li>
            </ul>
          </div>
        </div>
        <router-view></router-view>
      </div>
      <SetPhoto v-if="isExitPhoto && userInfo && userInfo.photoUrl" :visible.sync="isExitPhoto"
        :title="$t('mine.index.252217-39')" />
      <helpCenter v-if="isHelp" :visible.sync="isHelp" :scrollTop="helpTop" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getTime } from "@/mixins/getTime";
import SetPhoto from "@/components/SetPhoto.vue";
import helpCenter from '@/components/TransferHelp.vue'
export default {
  components: { SetPhoto, helpCenter },
  data() {
    return {
      isHelp: false,
      helpTop: 0,
      menuInd: undefined,
      cwmenusInd: 0,
      menus: [
        {
          name: '财务中心',
          list: [
            {
              name: this.$t('mine.index.252217-2'),
              link: "/finance/deposit",
              aid: 1,
              icon: require("@/assets/images/mine/icon/icon01.png"),
              iconHover: require("@/assets/images/mine/icon/icon01-1.png"),
            },
            {
              name: this.$t('mine.index.252217-4'),
              link: "/finance/withdrawal",
              aid: 3,
              icon: require("@/assets/images/mine/icon/icon03.png"),
              iconHover: require("@/assets/images/mine/icon/icon03-1.png"),
            },
            {
              name: this.$t('mine.index.252217-3'),
              link: "/finance/transfer",
              aid: 2,
              icon: require("@/assets/images/mine/icon/icon02.png"),
              iconHover: require("@/assets/images/mine/icon/icon02-1.png"),
            },
            {
              name: this.$t('mine.index.252217-44'),
              link: "/record/transRecord",
              aid: 4,
              icon: require("@/assets/images/mine/icon/icon04.png"),
              iconHover: require("@/assets/images/mine/icon/icon04-1.png"),
            },
            {
              name: this.$t('mine.index.252217-5'),
              link: "/record/betRecord",
              aid: 5,
              icon: require("@/assets/images/mine/icon/icon05.png"),
              iconHover: require("@/assets/images/mine/icon/icon05-1.png"),
            },
            {
              name: '取款卡',
              link: "/finance/banks",
              aid: 6,
              icon: require("@/assets/images/mine/icon/icon06.png"),
              iconHover: require("@/assets/images/mine/icon/icon06-1.png"),
            }
          ]
        },

        {
          name: '个人中心',
          list: [{
            name: '账号中心',
            link: "/mine/vip",
            aid: 7,
            icon: require("@/assets/images/mine/icon/icon07.png"),
            iconHover: require("@/assets/images/mine/icon/icon07-1.png"),
          },
          {
            name: this.$t('mine.index.252217-42'),
            link: "/record/welfare",
            aid: 8,
            icon: require("@/assets/images/mine/icon/icon08.png"),
            iconHover: require("@/assets/images/mine/icon/icon08-1.png"),
          },
          {
            name: this.$t('mine.index.252217-46'),
            link: "/mail",
            aid: 9,
            icon: require("@/assets/images/mine/icon/icon09.png"),
            iconHover: require("@/assets/images/mine/icon/icon09-1.png"),
          },
          {
            name: this.$t('mine.index.252217-40'),
            link: "/mine/userInfo",
            aid: 10,
            icon: require("@/assets/images/mine/icon/icon10.png"),
            iconHover: require("@/assets/images/mine/icon/icon10-1.png"),
          },
          {
            name: '密码修改',
            link: "/mine/password",
            aid: 11,
            icon: require("@/assets/images/mine/icon/icon14.png"),
            iconHover: require("@/assets/images/mine/icon/icon14-1.png"),
          },
          {
            name: '取款密码',
            link: "/mine/withdrawPassword",
            aid: 12,
            icon: require("@/assets/images/mine/icon/icon11.png"),
            iconHover: require("@/assets/images/mine/icon/icon11-1.png"),
          },
          {
            name: '手机邮箱',
            link: "/mine/contacts",
            aid: 13,
            icon: require("@/assets/images/mine/icon/icon12.png"),
            iconHover: require("@/assets/images/mine/icon/icon12-1.png"),
          },
          // {
          //   name: this.$t('mine.index.252217-43'),
          //   link: "/finance/wallet",
          //   aid: 14,
          //   icon: require("@/assets/images/mine/icon/icon13.png"),
          //   iconHover: require("@/assets/images/mine/icon/icon13-1.png"),
          // },
          // {
          //   name: this.$t('mine.index.252217-47'),
          //   link: "/feedback/list",
          //   aid: 15,
          //   icon: require("@/assets/images/mine/icon/icon12.png"),
          //   iconHover: require("@/assets/images/mine/icon/icon12-1.png"),
          // },
          {
            name: '个人资金明细',
            link: "",
            aid: 16,
            icon: require("@/assets/images/mine/icon/icon15.png"),
            iconHover: require("@/assets/images/mine/icon/icon15-1.png"),
          },
          {
            name: '红包',
            link: "",
            aid: 17,
            icon: require("@/assets/images/mine/icon/icon15.png"),
            iconHover: require("@/assets/images/mine/icon/icon15-1.png"),
          },
          ]
        }
      ],
      isExitPhoto: false,
      carkInd: 0,
      isShowRight: false,
      isHover: "",
      recordList: null, //投注列表
      // 卡片参数
      bankListMenu: [
        {
          name: this.$t('mine.index.252217-8'),
          type: 1,
          moreLink: "/finance/wallet",
          addLink: "/addBankCard",
        },
        {
          name: this.$t('mine.index.252217-9'),
          type: 2,
          moreLink: "/virtualCoinCard",
          addLink: "/virtualCoinCard",
        },
      ],
      bankList: null,
      // 存款参数
      ckList: null,
      // 反馈列表
      facebookList: null,
      // 活动列表
      activityList: null,
      userBankCardList: null,
    };
  },
  watch: {
    $route(val) {
      if (val.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }

      this.infoLeftVal();

    },
    isLogin(val) {
      if (val) {
        this.getUserInfo()
      }
    },
  },
  computed: {
    ...mapState(["userInfo", "message", 'accountMoney']),
    infoLevel() {
      const { realName, sex, birthDate, email, mobile } = this.userInfo
      const baseInfo = !!(realName && sex && birthDate)
      if (this.userInfo && mobile && email && baseInfo) {
        return '高'
      }

      if (this.userInfo && email && baseInfo) {
        return '中'
      }
      return '低'
    },
  },
  mounted() {
    this.infoRoute();
    this.infoLeftVal();
    this.getUnReadMessageCount();
    this.queryUserBankCardList()
  },
  methods: {

    jumpInfo(val) {
      // user-个人资料，pwd-密码，bank-银行，phone-手机号码，mail-邮箱
      switch (val) {
        case 'user':
          this.$router.push('/mine/userInfo')
          break;
        case 'pwd':
          this.$router.push('/mine/password')
          break;
        case 'bank':
          this.$router.push('/finance/banks')
          break;
        case 'phone':
          this.$router.push('/mine/contacts?type=1')
          break
        case 'mail':
          this.$router.push('/mine/contacts?type=2')
          break
      }

    },
    queryUserBankCardList() {
      this.$Api.queryUserBankCardList().then(res => {
        if (res) {
          this.userBankCardList = res.userBankCardList
        }
      })
    },
    openHelp(val) {
      this.isHelp = true
      document.body.style = 'overflow:hidden'
      this.helpTop = val
    },
    // 个人资料
    getUserInfo() {
      this.$Api.getUserInfo().then((res) => {
        if (res) {
          this.$store.dispatch("setUserInfoAc", res.userInfo);
        }
      });
    },
    // 设置头像
    setPhoto() {
      this.isExitPhoto = true;
    },
    // message
    getUnReadMessageCount() {
      this.$Api.getUnReadMessageCount().then((res) => {
        if (res) {
          const message =
            res.data.totalCount > 99 ? "99+" : res.data.totalCount;
          this.$store.dispatch("setMessageAc", message);
        }
      });
    },
    infoLeftVal() {
      this.menus.map((item) => {
        item.list.find((row) => {
          if (row.link === this.$route.fullPath) {
            this.cwmenusInd = row.aid;
          }
        });
      });
    },
    infoRoute() {
      if (this.$route.meta.isShow) {
        this.isShowRight = true;
      } else {
        this.isShowRight = false;
      }
    },
    tabMenu(item) {
      this.menuInd = item.aid;
      this.$router.push(item.link);
    },

    tabMenuCw(item) {
      if (item.link) {
        this.cwmenusInd = item.aid;
        this.$router.push(item.link);
      } else {
        this.$message('活动暂未开启')
      }
    },
    convertType(val) {
      let textVal = "";
      switch (val) {
        case 1:
          textVal = this.$t('mine.index.252217-53');
          break;
        case 2:
          textVal = this.$t('mine.index.252217-54');
          break;
        case 3:
          textVal = this.$t('mine.index.252217-55');
          break;
        case 4:
          textVal = this.$t('mine.index.252217-56');
          break;
        case 5:
          textVal = this.$t('mine.index.252217-57');
          break;
        case 6:
          textVal = this.$t('mine.index.252217-58');
          break;
      }
      return textVal;
    },
    typeName(val) {
      const options = [
        {
          value: 1,
          label: this.$t('mine.index.252217-59'),
        },
        {
          value: 2,
          label: this.$t('mine.index.252217-60'),
        },
        {
          value: 3,
          label: this.$t('mine.index.252217-61'),
        },
        {
          value: 4,
          label: this.$t('mine.index.252217-62'),
        },
        {
          value: 5,
          label: this.$t('mine.index.252217-63'),
        },
        {
          value: 6,
          label: this.$t('mine.index.252217-64'),
        },
        {
          value: 7,
          label: this.$t('mine.index.252217-65'),
        },
        {
          value: 8,
          label: this.$t('mine.index.252217-66'),
        },
        {
          value: 9,
          label: this.$t('mine.index.252217-67'),
        },
      ];
      return options.find((item) => item.value === val).label;
    },
  },
};
</script>

<style lang="scss" scoped>
.vipCenter {
  display: flex;
  padding-top: 100px;
  width: 1200px;
  min-height: 70vh;

  &-bg {
    padding-bottom: 30px;
    flex: 1;
  }
}

.user-left {
  box-shadow: 0 4px 16px rgba(152, 161, 182, 0.04);
  width: 220px;
  height: auto;
  margin-right: 16px;
  flex: none;
  display: flex;
  flex-direction: column;
}

.userInfo {

  .photo {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    border: 4px solid white;
    flex: none;

    &:hover {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("@/assets/images/xj.png") no-repeat rgba(0, 0, 0, 0.3) center center;
        background-size: 24px auto;
        border-radius: 50%;
      }
    }

    img {
      width: 100%;
      height: 100%;
      background-color: #35376d59;
      border-radius: 100%;
    }

    span {
      background: linear-gradient(0, #f3d662, #eeb147);
      font-size: 12px;
      position: absolute;
      right: -14px;
      top: 0;
      border-radius: 3px;
      color: white;
      font-weight: bold;
      padding: 0 4px;
      font-style: italic;
      transform: scale(.9) scaleY(.8);
      text-indent: 1px;
    }
  }

  .name {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .text {
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: white;
      font-weight: 400;
      font-family: DIN, PingFangSC-Regular, sans-serif !important;
    }

    .level {
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: pointer;
      width: 52px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .time {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    color: white;
  }


}

.user-nav {
  background: linear-gradient(180deg, #fdfeff 13.02%, #fdfeff 86.98%, #edf7ff);
  border-radius: 16px;
  margin-bottom: 12px;
  box-shadow: 0 4px 10px rgba(125, 168, 207, 0.35), inset 0 -2px 0 #93b6d3, inset 0 -3px 2px #aecce4, inset 0 1px 1px #fff;

  &:first-child {
    flex: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    height: 46px;
    padding-left: 22px;
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 46px;
    color: #3f5075;
    text-align: left;
    border-bottom: 1px solid rgba(233, 237, 246, .8);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 11px;
      width: 4px;
      height: 22px;
      border-radius: 0 4px 4px 0;
      background: #2599f8;
    }
  }

  .user-nav-ul {
    padding: 0 0 16px;
    border-bottom: 1px solid #e4e8eb;

    &:last-child {
      border-bottom: none;
    }

    li {
      position: relative;
      overflow: hidden;
      background: transparent;
      border-left: 3px solid transparent;
      display: flex;
      align-items: center;
      cursor: pointer;
      color: #788b9d;
      height: 44px;
      padding-left: 20%;

      .number {
        background: linear-gradient(180deg, #ff8091, #ee4e56);
        font-size: 12px;
        color: white;
        border-radius: 50%;
        height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 6px;
      }

      &.hover {
        background: linear-gradient(270deg, #37cfff 1.28%, #37b2fd 31.34%, #4fa2ff 94.2%, #3d99ff 97.68%, #29b9ff);

        .icon {
          .xz {
            display: block;
          }

          .mr {
            display: none;
          }
        }

        .text {
          color: white;
        }
      }

      &:hover {
        color: #2599f8;
      }

      .icon {
        width: 30px;
        margin: 0 10px 0 0;

        img {
          width: 100%;
        }

        .xz {
          display: none;
        }
      }
    }
  }
}

.account-dj {
  color: white;
  font-size: 12px;
  margin-top: 16px;
}

.account-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15%;
  margin-top: 6px;

  span {
    width: 22px;
    height: 22px;
    opacity: .5;

    &.hover {
      opacity: 1;
    }

    img {
      width: 100%;
    }
  }
}

.message {
  background: red;
  color: white;
  padding: 2px 7px;
  font-size: 12px;
  margin-left: 6px;
  line-height: 1;
  border-radius: 8px;
}

.user-right {
  display: flex;
  width: 964px;
  flex: 1;
  flex-direction: column;

  .info {
    width: 160px;

    .name {
      font-size: 16px;
      font-weight: 500;
    }

    .money {
      font-style: normal;
      color: #2599f8;
    }
  }

  &-info {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #fdfeff 13.02%, #fdfeff 86.98%, #edf7ff);
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(125, 168, 207, .35), inset 0 -2px 0 #93b6d3, inset 0 -3px 2px #aecce4, inset 0 1px 1px #fff;
    padding: 20px;
    margin-bottom: 16px;

    .photo {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin-right: 16px;
      padding: 16px;
      position: relative;

      &:hover {
        span {
          opacity: 1;
          cursor: pointer;
        }
      }

      img {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
        border-radius: 100%;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 12px;
        background: rgba(0, 0, 0, .2);
        border-radius: 100%;
        opacity: 0;
        transition: all .4s;
      }
    }

    .info-list {
      flex: 1;
      padding: 0 20px;

      ul {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;

        li {
          font-size: 14px;

          img {
            width: 32px;
          }

          .text-blue {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
