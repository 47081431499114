<template>
    <div class="weburl">
        <div class="weburl-pop">
            <div class="weburl-title">
                <h2>必威体育官网或APP打不开，怎么办？</h2>
                <span class="el-icon-close weburl-close" @click="close"></span>
            </div>
            <div class="weburl-main">
                <p class="weburl-p">1. 通过桌面图标上的网址，重新下载APP：</p>
                <div class="weburl-context">
                    <img v-lazy="baseInfo[1].configValue" class="ico" />
                    <div class="weburl-context-url" v-if="baseInfo && baseInfo[5]">{{ baseInfo[5].configValue }}</div>
                    <img src="@/assets/images/home/add01.png" class="img" />
                </div>
                <p class="weburl-p">2. 记住您的官方永久网址，随时畅玩：</p>
                <div class="weburl-context bg">
                    <p>· 官方永久网址</p>
                    <div class="weburl-context-row">
                        <div class="weburl-context-item">
                            <i class="el-icon-search"></i>
                            <div class="item">{{ baseInfo[5].configValue }}</div>
                        </div>
                        <div class="weburl-context-copy" v-if="baseInfo && baseInfo[5]"
                            @click="copyLink(baseInfo[5].configValue)">
                        </div>
                    </div>
                </div>
                <p class="weburl-p">3. 一键收藏到书签：</p>
                <div class="weburl-context">
                    <div class="weburl-context-url" v-if="baseInfo && baseInfo[5]" style="left: 43px; top: 25px;">{{
                        baseInfo[5].configValue }}</div>
                    <img src="@/assets/images/home/add02.png" class="img" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        close() {
            this.$emit('update:visible', false)
        }
    },
    computed: {
        ...mapState(['baseInfo'])
    },
}
</script>
<style scoped lang="scss">
.weburl {
    position: fixed;
    z-index: 30;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    &-pop {
        width: 500px;
        user-select: none;
        border-radius: 16px;
        overflow: hidden;
        min-height: 200px;
        background: linear-gradient(180deg, #fdfeff 13.02%, #fdfeff 86.98%, #edf7ff);
        box-shadow: 0 4px 10px rgba(125, 168, 207, .35), inset 0 -2px 0 #93b6d3, inset 0 -3px 2px #aecce4, inset 0 1px 1px #fff;
    }

    &-title {
        // width: 500px;
        height: 48px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background: linear-gradient(270deg, #99e7ff, #37cfff 1.84%, #37b2fd 30.61%, #4fa2ff 94.14%, #3a97ff 97.65%, #21b6ff);

        h2 {
            font-size: 18px;
        }

        .weburl-close {
            font-size: 24px;
            position: absolute;
            right: 10px;
            cursor: pointer;
        }
    }

    &-main {
        padding: 6px 30px 30px;
    }

    &-context {
        width: 100%;
        position: relative;

        &.bg {
            background: #f6f6f6;
            padding: 8px 36px;
        }

        .img {
            width: 100%;
        }

        .ico {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 12px;
            left: 60px;
            border-radius: 8px;
        }

        &-url {
            position: absolute;
            font-size: 12px;
            color: #7981a4;
            top: 24px;
            left: 120px;
            transform: scale(.75);
        }

        &-row {
            display: flex;
            align-items: center;

        }

        &-item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            border: 0.5px solid #e4e6ed;
            border-radius: 15px;
            padding: 4px 24px 4px 8px;

            i {
                font-size: 12px;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #bbbbbb;
            }

            .item {
                flex: 1;
                text-align: center;
                font-size: 12px;
                color: #7981a4;
            }
        }

        &-copy {
            width: 24px;
            height: 24px;
            margin-left: 8px;
            background: url('@/assets/images/home/add03.png') no-repeat center white;
            background-size: 16px;
            border-radius: 50%;
        }
    }

    &-p {
        margin-top: 16px;
        padding-bottom: 4px;
    }
}
</style>